import PropTypes from "prop-types"
import React, { useState } from "react"
import NavLink from './navigation-link'
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt, faChevronRight } from '@fortawesome/free-solid-svg-icons'


const Navigation = ({ siteTitle }) => {
  const [open, setOpen] = useState(false);

  var toggleMenu = (event) => {
    setOpen(!open);
  }

  return <nav className="bg-black text-white mb-16">
    <div className="container mx-auto flex items-center justify-begin flex-wrap px-8">
      <div className="block lg:hidden">
        <button className="flex items-center px-3 py-4 text-white hover:text-white hover:border-white" onClick={toggleMenu}>
          <svg className="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          <span className="ml-4">Menu</span>
        </button>
      </div>
      <div className={`${(open ? '' : 'hidden')} w-full block flex-grow lg:flex lg:items-center lg:w-auto pb-4 lg:pb-0`}>
        <div className="lg:flex-grow">
          <NavLink to="/about-us">Over de opleiding</NavLink>
          <NavLink to="/success-stories">Succesverhalen</NavLink>
          <NavLink to="/partners">Partners</NavLink>
          <NavLink to="/news">Nieuws</NavLink>
          <NavLink to="/contact">Contact</NavLink>
          <a target="_blank" rel="noopener noreferrer" href="mailto:traub@socialreturn.nu?subject=Ik zoek een stucadoor" className="border-black border-b-4 hover:border-traub-yellow py-4 text-white block mt-4 no-underline lowercase font-bold mr-8 lg:inline-block lg:mt-0">Ik zoek een stucadoor <FontAwesomeIcon className="h-4" icon={faExternalLinkAlt} /></a>
          <span className="lg:float-right lg:inline-block">
          <Link to="/getting-started" className="bg-traub-green border-traub-green border-b-4 py-4 px-8 text-white block mt-4 no-underline lowercase font-bold mr-4 lg:inline-block lg:mt-0">Word stucadoor ›</Link>
          </span>
        </div>
      </div>
    </div>
  </nav>
}

Navigation.propTypes = {
  currentPage: PropTypes.string,
}

Navigation.defaultProps = {
  currentPage: ``,
}

export default Navigation
