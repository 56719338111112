import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image/withIEPolyfill"


const Header = ({ siteTitle }) => {
  const { header } = useStaticQuery(
    graphql`query {
      header: file(relativePath: { eq: "header.png" }) {
        childImageSharp {
          fixed(width: 270) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }`
  )

  return <header className="bg-traub-yellow">
    <div className="container mx-auto px-8 py-16">
      <div className="flex items-center flex-wrap flex-shrink-0 text-white mr-6">
      <Link
            to="/"
            className=""
          >
            <Img className="mr-8" loading="eager" objectFit="scale-down" objectPosition="100% 50%" fadeIn={false} fixed={header.childImageSharp.fixed} alt="Traub Stuc" />
        </Link>
        <h1 className="inline-block text-4xl">
          <Link
            to="/"
            className="text-black no-underline"
          >
            Praktijkopleiding stucadoor Haaglanden
          </Link>
        </h1>
      </div>
    </div>
  </header>
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
