import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const NavLink = ({to, children}) => {
  return <Link to={to} activeClassName="border-traub-yellow" className={`border-black border-b-4 hover:border-traub-yellow py-4 text-white block mt-4 no-underline lowercase font-bold mr-8 lg:inline-block lg:mt-0`}>{children}</Link>
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
}

NavLink.defaultProps = {
  to: "",
}

export default NavLink
