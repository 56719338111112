import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'


const Footer = ({ siteTitle }) => {
  const {contact} = useStaticQuery(graphql`
{
  contact: dataYaml {
    coordinates
    zoom
    street
    zipcode
    city
    email
    address_name
    michel {
      visual
      data
    }
    peet {
      visual
      data
    }
    phone {
      visual
      data
    }
  }
}
  `);

  return <footer className="bg-traub-yellow mt-16">
    <div className="container mx-auto px-8 py-16">
      <div className="text-black grid grid-cols-1 lg:grid-cols-12 lg:gap-5">
        <div className="lg:col-span-5">
          <h3 className="text-black">
            Traubstuc - Praktijk Opleidingcentrum Stucadoor Haaglanden
          </h3>
          <p>
            Traub stuc heeft een ambitieus beeld van de toekomst. Wij vinden dat iedereen een kans moet krijgen om een mooi vak te leren om in zijn eigen levensonderhoud te voorzien.
          </p>
        </div>
        <div className="lg:col-span-3 lg:col-start-7">
          <h3 className="text-black">
            Adres
          </h3>
          <p>
            {contact.address_name}<br />
            {contact.street}<br />
            {contact.zipcode} {contact.city}
          </p>
        </div>
        <div className="lg:col-span-3">
          <h3 className="text-black">
            Contact
          </h3>
          <p>
            <a className="text-black no-underline" href={`tel:${contact.phone.data}`}><FontAwesomeIcon icon={faPhone} /> {contact.phone.visual}</a><br />
            <a className="text-black no-underline" href={`mailto:${contact.email}`}><FontAwesomeIcon icon={faEnvelope} /> {contact.email}</a>
          </p>
        </div>
        
      </div>
    </div>
  </footer>
}

Footer.propTypes = {
  
}

Footer.defaultProps = {
  
}

export default Footer
